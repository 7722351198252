import { Divider, Paper, Typography } from "@mui/material";
import {
  IOrganizationUnit,
  ISignageConfig,
  NendaProduct,
  SignageSlotAmount,
} from "../../../../../../types/NendaTypes";
import { t } from "i18next";
import { useSelector } from "react-redux";
import { CustomerPortalState } from "../../../store";
import constants from "@shared/constants";
import { selectNavigatedCompanyId } from "../../../store/reducers/workspaceReducer";
import NendaProducts from "../../Premise/NendaProducts";
import SignageSlots from "../../Premise/Features/SignageSlots";
import CompanySelect from "../../Premise/CompanySelect";
import ContentChannels from "../../Premise/Features/ContentChannels";
import { getCompanyIdByPremise } from "../../../../../utils/company";
import ConditionalFeaturesSelect, {
  ConditionalFeature,
} from "../../Premise/ConditionalFeaturesSelect";
import { FC } from "react";

interface FeaturesProps {
  premise: IOrganizationUnit;
  premiseFeatures: IOrganizationUnit & {
    signageConfig: ISignageConfig;
  };
  handleProductChange: (productName: NendaProduct) => void;
  handleCompanyChange: (companyId: string) => void;
  handleSignageSlotChange: (value: SignageSlotAmount) => void;
  handleContentChannelsChange: (contentChannels: string[]) => void;
  handleStreamingContentMangerEnabledChange: (value: boolean) => void;
  handleSignageAsContentEnabledChange: (value: boolean) => void;
  handlePromotionsEnabledChange: (value: boolean) => void;
}
const Features: FC<FeaturesProps> = ({
  premise,
  premiseFeatures,
  handleProductChange,
  handleCompanyChange,
  handleSignageSlotChange,
  handleContentChannelsChange,
  handleStreamingContentMangerEnabledChange,
  handleSignageAsContentEnabledChange,
  handlePromotionsEnabledChange,
}) => {
  const nendaProducts = premiseFeatures.nendaProducts || [];
  const companyId = useSelector(selectNavigatedCompanyId);
  const company = useSelector((state: CustomerPortalState) =>
    state.company.companies.find((c) => c._id === companyId)
  );

  const getInheritedSignageSlotValue = () => {
    if (premise?.signageConfig?.numberOfHourlySignageSlots) {
      return premise?.signageConfig?.numberOfHourlySignageSlots;
    }
    if (company?.signageConfig?.numberOfHourlySignageSlots) {
      return company?.signageConfig?.numberOfHourlySignageSlots;
    }
    const { SIGNAGE_SLOTS_PER_HOUR } = constants.Signage();
    return SIGNAGE_SLOTS_PER_HOUR;
  };

  const companyIdByPremise = getCompanyIdByPremise(
    premiseFeatures as IOrganizationUnit
  );

  const conditionalFeatures: ConditionalFeature[] = [
    {
      label: t(
        "customerportal.settings.premise_settings.streaming_content_manager"
      ),
      onChange: handleStreamingContentMangerEnabledChange,
      checked: premiseFeatures.streamingContentManagerEnabled || false,
      visible:
        premiseFeatures.nendaProducts?.includes(NendaProduct.STREAMING_APP) ||
        false,
    },
    {
      label: t("customerportal.settings.premise_settings.signage_as_content"),
      onChange: handleSignageAsContentEnabledChange,
      checked: premiseFeatures.signageConfig?.isSignageAsContentEnabled,
      visible:
        premiseFeatures.nendaProducts?.includes(NendaProduct.SIGNAGE) || false,
    },
    {
      label: t("customerportal.settings.premise_settings.promotions"),
      onChange: handlePromotionsEnabledChange,
      checked: premiseFeatures.signageConfig?.arePromotionsEnabled,
      visible:
        premiseFeatures.nendaProducts?.includes(NendaProduct.SIGNAGE) || false,
    },
  ];

  return (
    <Paper sx={{ p: 1 }}>
      <NendaProducts
        selectedProducts={nendaProducts}
        availableProducts={Object.values(NendaProduct)}
        onProductSelect={handleProductChange}
      />
      {conditionalFeatures.length > 0 && (
        <ConditionalFeaturesSelect features={conditionalFeatures} />
      )}
      <Divider sx={{ my: "1rem" }} />
      <Typography variant="body2" sx={{ my: "0.5rem" }}>
        {t("common.company")}
      </Typography>
      <CompanySelect
        company={companyIdByPremise || ""}
        setCompany={handleCompanyChange}
      />
      <Divider sx={{ my: "1rem" }} />
      <Typography variant="body2">{t("common.signage_settings")}</Typography>
      <SignageSlots
        selectedValue={
          premiseFeatures.signageConfig?.numberOfHourlySignageSlots || 0
        }
        handleSignageSlotChange={handleSignageSlotChange}
        parentValue={getInheritedSignageSlotValue()}
      />

      <Typography variant="body2" sx={{ my: "0.5rem" }}>
        {t("common.content_channels")}
      </Typography>
      <ContentChannels
        premise={premise}
        contentChannels={premiseFeatures.contentChannels || []}
        setContentChannels={handleContentChannelsChange}
      />
    </Paper>
  );
};

export default Features;
