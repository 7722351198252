import {
  Box,
  Button,
  CircularProgress,
  MenuItem,
  Stack,
  Tab,
  Tabs,
  Typography,
  useTheme,
} from "@mui/material";
import { t } from "i18next";
import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import {
  IAsset,
  NendaProduct,
  Resource,
  Scope,
  SortDirection,
} from "../../../../types/NendaTypes";
import {
  AssetSortableFields,
  IAssetListRequest,
  IListResponse,
} from "../../../../types/RequestTypes";
import AssetDetailsDrawer from "../components/AssetLibrary/AssetDetailsDrawer";
import AssetGrid from "../components/AssetLibrary/AssetGrid";
import UploadAssetModal from "../components/AssetLibrary/UploadAssetModal";
import {
  canEditCompanyAssets,
  canEditPremiseAssets,
} from "../components/AssetLibrary/helpers";
import PermissionsGate from "../components/PermissionGate";
import { CustomerPortalState, store } from "../store";
import {
  GetCompanyAssets,
  GetPremiseAssets,
  ToggleCreateAssetDialog,
} from "../store/reducers/assetReducer";
import { selectUserRole } from "../store/reducers/authReducer";
import { selectNavigationScope } from "../store/reducers/workspaceReducer";
import InputSelect from "../ui-components/input/InputSelect";
import ShowMoreButton from "../ui-components/table/ShowMoreButton";

const pageSize = 12;

type SortOption = {
  label: string;
  value: string;
  direction?: SortDirection;
};

const AssetsPage: React.FC = () => {
  const sortOptions: SortOption[] = [
    {
      label: t("customerportal.pages.promotions.filter.sort.oldest"),
      value: "",
      direction: undefined,
    },

    {
      label: t("customerportal.pages.promotions.filter.sort.name_asc"),
      value: "name",
      direction: SortDirection.ASCENDING,
    },
    {
      label: t("customerportal.pages.promotions.filter.sort.name_desc"),
      value: "name",
      direction: SortDirection.DESCENDING,
    },
  ];

  const theme = useTheme();
  const scope = useSelector(selectNavigationScope);
  const premiseId = scope.premise;
  const companyId = scope.company;
  const [selectedAsset, setSelectedAsset] = useState<IAsset | null>(null);
  const [showDrawer, setShowDrawer] = useState(false);
  const [sortOption, setSortOption] = useState<SortOption>(
    sortOptions[0] || {}
  );
  const dispatch = useDispatch();
  const { type } = useParams<{ id: string; type: string }>();

  const userRole = useSelector(selectUserRole);
  const companyAssets = useSelector(
    (state: CustomerPortalState) => state.asset.paginatedCompanyAssets
  );
  const premiseAssets = useSelector(
    (state: CustomerPortalState) => state.asset.paginatedPremiseAssets
  );

  const [tab, setTab] = useState<string>(type || Resource.Company);

  const companyLoadingStatus = useSelector(
    (state: CustomerPortalState) => state.asset.paginatedCompanyAssets.status
  );
  const premiseLoadingStatus = useSelector(
    (state: CustomerPortalState) => state.asset.paginatedPremiseAssets.status
  );

  const isLoading =
    companyLoadingStatus === "loading" || premiseLoadingStatus === "loading";

  const getAssets = ({ entity, page }) => {
    if (!entity) return;
    const action =
      entity === Resource.Company ? GetCompanyAssets : GetPremiseAssets;
    const actionEntity =
      entity === Resource.Company ? scope.company : scope.premise;
    const filter: IAssetListRequest = {
      pagination: {
        page,
        pageSize,
      },
      filter: {
        product: NendaProduct.SIGNAGE,
      },
      ...(sortOption.value &&
        sortOption.direction && {
          sort: {
            sortBy: AssetSortableFields.NAME,
            sortOrder: sortOption.direction,
          },
        }),
    };

    if (actionEntity) {
      dispatch(action(actionEntity, { ...filter }));
    }
  };

  const handleTabChange = (_event, value) => setTab(value);

  useEffect(() => {
    if (type === "company") {
      setTab("company");
    } else {
      setTab("premise");
    }
  }, [type]);

  const handleSortOrderChange = useCallback((event) => {
    const newSortOption =
      sortOptions.find(
        (item) => item.value + item.direction === event.target.value
      ) || sortOptions[0];

    setSortOption(newSortOption);
  }, []);

  useEffect(() => {
    companyId &&
      tab === Resource.Company &&
      !companyAssets.data.length &&
      getAssets({ entity: Resource.Company, page: 1 });
    premiseId &&
      tab === Resource.Premise &&
      !premiseAssets.data.length &&
      getAssets({ entity: Resource.Premise, page: 1 });
  }, [JSON.stringify({ companyId, sortOption, tab, premiseId })]);

  const assets: IListResponse<IAsset> | undefined =
    tab === Resource.Premise ? premiseAssets : companyAssets;

  const handleAssetClick = (asset: IAsset) => {
    setSelectedAsset(asset);
    setShowDrawer(true);
  };

  const handleCloseDetails = () => {
    setShowDrawer(false);
  };

  const handleShowCreateAsset = () => {
    store.dispatch(ToggleCreateAssetDialog());
  };

  const handleShowMore = () => {
    getAssets({
      entity: tab === Resource.Premise ? Resource.Premise : Resource.Company,
      page: assets.page + 1,
    });
  };

  const companyAssetsLastPage =
    companyAssets.page * companyAssets.pageSize >= companyAssets.filteredCount;
  const premiseAssetsLastPage =
    premiseAssets.page * premiseAssets.pageSize >= premiseAssets.filteredCount;
  const onLastPage =
    tab === Resource.Premise ? premiseAssetsLastPage : companyAssetsLastPage;

  const canCreateAsset: boolean = userRole
    ? tab === Resource.Company
      ? canEditCompanyAssets(userRole)
      : canEditPremiseAssets(userRole)
    : false;
  return (
    <Box>
      {showDrawer && (
        <AssetDetailsDrawer
          onClose={handleCloseDetails}
          open={true}
          asset={selectedAsset}
        />
      )}
      {companyId && (
        <UploadAssetModal
          premise={tab === Resource.Premise ? premiseId : undefined}
          company={companyId}
        />
      )}
      <Box>
        <Stack direction="row" alignItems="center" spacing={2}>
          <Typography variant="h3">
            {t("customerportal.pages.assets.title")}
          </Typography>
          <PermissionsGate
            restriction={{
              resource: Resource.Asset,
              scopes: [Scope.CanCreate],
            }}
          >
            <>
              {canCreateAsset && (
                <Button
                  variant="contained"
                  size="small"
                  onClick={handleShowCreateAsset}
                >
                  {tab === Resource.Premise
                    ? t("customerportal.pages.assets.create_premise_asset")
                    : t("customerportal.pages.assets.create_company_asset")}
                </Button>
              )}
            </>
          </PermissionsGate>
        </Stack>
      </Box>
      <Box marginTop={2}>
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="flex-end"
          minHeight="5rem"
          sx={{
            [theme.breakpoints.down("sm")]: { flexDirection: "column-reverse" },
          }}
        >
          <Tabs
            value={tab}
            aria-label="Asset type"
            onChange={handleTabChange}
            sx={{ display: "inline-flex", alignItems: "flex-end" }}
          >
            {scope.company && (
              <Tab
                label={t("customerportal.pages.assets.tabs.company_assets")}
                value="company"
                sx={{
                  color: "primary.main",
                  background:
                    tab === Resource.Company
                      ? theme.palette.transparent.purple
                      : "transparent",
                  borderRadius: "0.3rem 0.3rem 0 0",
                }}
              />
            )}
            {scope.premise && (
              <Tab
                label={t("customerportal.pages.assets.tabs.premise_assets")}
                value={Resource.Premise}
                sx={{
                  color: "primary.main",
                  background:
                    tab === Resource.Premise
                      ? theme.palette.transparent.purple
                      : "transparent",
                  borderRadius: "0.5rem 0.5rem 0 0",
                }}
              />
            )}
          </Tabs>
          <Box>
            <InputSelect
              id="sort-order-select"
              value={sortOption?.value + sortOption?.direction}
              title="Sort"
              onChange={handleSortOrderChange}
              defaultValue={sortOptions[0]?.value + sortOptions[0]?.direction}
              variant="outlined"
              sx={{ textTransform: "capitalize", mb: "0.3rem", mr: "1rem" }}
            >
              {sortOptions.map((item, i) => {
                return (
                  <MenuItem
                    key={item?.value! + i}
                    value={item?.value + item?.direction}
                    sx={{ textTransform: "capitalize" }}
                  >
                    {item.label}
                  </MenuItem>
                );
              })}
            </InputSelect>
          </Box>
        </Stack>
        {assets?.data?.length < 1 && (
          <Box
            sx={{
              width: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              background: theme.palette.transparent.purple,
              borderRadius: "0.7rem",
              padding: "2rem",
            }}
          >
            <>
              {isLoading ? (
                <CircularProgress />
              ) : (
                <Typography variant="body1">
                  {t("customerportal.pages.assets.no_assets")}
                </Typography>
              )}
            </>
          </Box>
        )}
        {assets?.data?.length > 0 && (
          <>
            <AssetGrid
              assets={assets}
              onSelectAsset={handleAssetClick}
              isLoading={isLoading}
            />
            <ShowMoreButton onClick={handleShowMore} disabled={onLastPage} />
          </>
        )}
      </Box>
    </Box>
  );
};

export default AssetsPage;
