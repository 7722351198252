import { Box, Grid, Tooltip, Typography, useTheme } from "@mui/material";
import { useSelector } from "react-redux";
import { Hour, Minute } from "../../../../../types/HelperTypes";
import {
  ContentScheduleType,
  Time,
  IContentChannel,
} from "../../../../../types/NendaTypes";
import { getPrettyTimeRange } from "../../../../utils/timeline";
import { selectContentChannels } from "../../store/reducers/contentChannelReducer";
import PlaceholderImage, {
  PlaceholderImageType,
} from "../../ui-components/placeholder/PlaceholderImage";
import { ContentTimelineInterval } from "../../store/reducers/screenReducer";
import { t } from "i18next";
import DisabledFeatures from "../Content/components/DisabledFeatures";
import React from "react";
import { selectSignagePlaylists } from "../../store/reducers/signagePlaylistReducer";

export function isContentChannel(item: any): item is IContentChannel {
  return (
    item && typeof item === "object" && "playlist" in item && "hasSound" in item
  );
}

interface PlaylistTimelineProps {
  playlist: ContentTimelineInterval[];
  height?: string;
}

const PlaylistTimeline: React.FC<PlaylistTimelineProps> = ({
  playlist,
  height,
}) => {
  const hoursInADay = 24;
  const contentChannels = useSelector(selectContentChannels);
  const signagePlaylists = useSelector(selectSignagePlaylists);
  const calculateLeftPosition = (time: Time): number => {
    if (!time) return 0;
    return ((time.hour + time.minute / 60) / hoursInADay) * 100;
  };

  const calculateWidth = (startTime: Time, endTime: Time): number => {
    const startPercentage = calculateLeftPosition(startTime);
    const endPercentage =
      calculateLeftPosition(
        endTime.hour === 0 && endTime.minute === 0
          ? { hour: 24 as Hour, minute: 0 }
          : endTime
      ) - 0.1; // - 0.1 is a hack to make space between ranges that join together

    return endPercentage - startPercentage;
  };

  const theme = useTheme();

  const intervalColors = {
    [ContentScheduleType.SCHEDULED]: theme.palette.primary.light,
    [ContentScheduleType.OVERRIDE]: theme.palette.transparent.hardPurple,
    [ContentScheduleType.DEFAULT]: theme.palette.transparent.black,
  };
  return (
    <Box
      sx={(theme) => ({
        height: height || "3rem",
        position: "relative",
        py: "0.2rem",
        width: "100%",
        background: theme.palette.transparent.purple,
      })}
    >
      {playlist.map((interval) => {
        const content = [...contentChannels, ...signagePlaylists];

        const contentItem = content.find((c) => c._id === interval.contentId);

        const startTime = {
          hour: interval.start.getUTCHours() as Hour,
          minute: interval.start.getUTCMinutes() as Minute,
        };

        const endTime = {
          hour: interval.end.getUTCHours() as Hour,
          minute: interval.end.getUTCMinutes() as Minute,
        };

        const prettyTime = getPrettyTimeRange({
          start: startTime,
          end: endTime,
        });

        return (
          <Tooltip
            key={interval.start.getTime()}
            enterDelay={500}
            title={
              <Grid
                container
                columns={{ xs: 2, md: 2 }}
                spacing={1}
                sx={{
                  minWidth: "300px",
                  height: "250px",
                  m: "0rem 0rem 1rem 0rem",
                }}
              >
                <Grid item xs={1}>
                  <Box
                    sx={{
                      position: "relative",
                      background: "#ccc",
                      height: "100%",
                      width: "100%",
                    }}
                  >
                    {isContentChannel(contentItem) ? (
                      contentItem.posterUrl ? (
                        <img
                          src={contentItem.posterUrl}
                          style={{
                            objectFit: "cover",
                            backgroundPosition: "center",
                            backgroundSize: "cover",
                            width: "150px",
                            height: "100%",
                          }}
                        />
                      ) : (
                        <PlaceholderImage
                          type={PlaceholderImageType.POSTER_IMAGE}
                        />
                      )
                    ) : (
                      <PlaceholderImage
                        type={PlaceholderImageType.POSTER_IMAGE}
                      />
                    )}
                  </Box>
                </Grid>
                <Grid item xs={1}>
                  <Box sx={{ width: "100%", height: "100%", px: "1rem" }}>
                    <Typography variant="body1" fontSize="0.9rem">
                      {contentItem?.name}
                    </Typography>
                    <Typography variant="body1" fontSize="0.7rem">
                      {prettyTime}
                    </Typography>
                    <Typography variant="body1" fontSize="0.7rem">
                      {t(
                        `customerportal.pages.dashboard.screen.content.timeline.interval_type.${interval.type}`
                      )}
                    </Typography>
                    {contentItem && (
                      <DisabledFeatures
                        content={contentItem}
                        iconColor="white"
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          zIndex: 1,
                          fill: "white",
                          p: "0.2rem",
                        }}
                      />
                    )}
                  </Box>
                </Grid>
              </Grid>
            }
          >
            <Box
              sx={{
                position: "absolute",
                top: "0.3rem",
                bottom: "0.3rem",
                p: "0.2rem",
                backgroundColor: intervalColors[interval.type],
                overflow: "hidden",
                borderRadius: "0.1rem 1rem 0.1rem 0.1rem",
                left: `${calculateLeftPosition(startTime)}%`,
                width: `${calculateWidth(startTime, endTime)}%`,
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  height: "100%",
                  gap: "0.2rem",
                  overflow: "hidden",
                }}
              >
                <Box
                  sx={(theme) => ({
                    backgroundColor: "black",
                    minWidth: "40px",
                    maxWidth: "60px",
                    height: "100%",
                    borderRadius: "0.1rem",
                    p: "0.1rem",
                    [theme.breakpoints.down("md")]: {
                      display: "none",
                    },
                  })}
                >
                  {isContentChannel(contentItem) ? (
                    contentItem.logo?.locator ? (
                      <img
                        src={contentItem.logo.locator}
                        style={{
                          objectFit: "cover",
                          height: "100%",
                          width: "100%",
                          backgroundPosition: "center",
                        }}
                      />
                    ) : (
                      <PlaceholderImage
                        type={PlaceholderImageType.CHANNEL_LOGO}
                      />
                    )
                  ) : (
                    <PlaceholderImage
                      type={PlaceholderImageType.CHANNEL_LOGO}
                    />
                  )}
                </Box>
                <Box>
                  <Typography
                    variant="body2"
                    fontSize={"0.7rem"}
                    sx={{
                      textOverflow: "ellipsis",
                      whiteSpace: "nowrap",
                      width: "20px",
                      color: "white",
                    }}
                  >
                    {contentItem?.name}
                  </Typography>
                  <Typography
                    variant="body1"
                    fontSize={"0.6rem"}
                    sx={{
                      textOverflow: "ellipsis",
                      whiteSpace: "nowrap",
                      width: "20px",
                      color: "white",
                    }}
                  >
                    {prettyTime}
                  </Typography>
                </Box>
              </Box>
            </Box>
          </Tooltip>
        );
      })}
    </Box>
  );
};

export default PlaylistTimeline;
