import { Box, SxProps, Tooltip, useTheme } from "@mui/material";
import {
  IContentChannel,
  ISignagePlaylist,
} from "../../../../../../types/NendaTypes";
import PromotionsAdsDisabled from "../../../assets/images/svg/promotions-blocked.svg";
import AdsDisabled from "../../../assets/images/svg/ad-block-icon.svg";
import { t } from "i18next";
import { isContentChannel } from "../../DailyPlaylist/PlaylistTimeline";

export const adsDisabled = (content: IContentChannel | ISignagePlaylist) => {
  if (isContentChannel(content)) {
    return (
      !content.adPermissions.external.bannerAllowed &&
      !content.adPermissions.external.fullTakeoverAllowed
    );
  } else {
    return true;
  }
};
export const signageDisabled = (
  content: IContentChannel | ISignagePlaylist
) => {
  if (isContentChannel(content)) {
    return (
      !content.adPermissions.signage.bannerAllowed &&
      !content.adPermissions.signage.fullTakeoverAllowed
    );
  }
  return true;
};

const DisabledFeatures = ({
  content,
  sx,
  iconColor,
}: {
  content: IContentChannel | ISignagePlaylist;
  sx: SxProps;
  iconColor?: string;
}) => {
  const theme = useTheme();

  const adsIsDisabled = adsDisabled(content);
  const signageIsDisabled = signageDisabled(content);
  const signageOrAdsIsDisabled = adsIsDisabled || signageIsDisabled;

  const items = [
    {
      value: signageIsDisabled,
      styles: {
        height: "1.3rem",
        fill: iconColor || theme.palette.primary.main,
        color: iconColor || theme.palette.primary.main,
      },
      icon: PromotionsAdsDisabled,
      description: "customerportal.notifications.signage_disabled",
    },
    {
      value: adsIsDisabled,
      styles: {
        width: "1.3rem",
        fill: iconColor || theme.palette.primary.main,
      },
      icon: AdsDisabled,
      description: "customerportal.notifications.ads_disabled",
    },
  ];
  return (
    <>
      {signageOrAdsIsDisabled && (
        <Box sx={{ ...sx }}>
          {items.map((item) => {
            return (
              item.value && (
                <Tooltip title={t(item.description)} enterDelay={1000}>
                  <Box>
                    <item.icon
                      style={{
                        ...item.styles,
                        height: "1.3rem",
                        margin: "0.2rem",
                      }}
                    />
                  </Box>
                </Tooltip>
              )
            );
          })}
        </Box>
      )}
    </>
  );
};

export default DisabledFeatures;
