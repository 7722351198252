import {
  Alert,
  Box,
  Checkbox,
  FormControlLabel,
  Grid,
  Typography,
  useTheme,
} from "@mui/material";
import { t } from "i18next";
import { IContentChannel, IRoom } from "../../../../../../types/NendaTypes";
import { adsDisabled, signageDisabled } from "./DisabledFeatures";

const SetDefaultChannel = ({
  screens,
  selectedScreens,
  onSelect,
  channel,
}: {
  screens: IRoom[];
  selectedScreens: string[];
  onSelect: (screens: string[]) => void;
  channel?: IContentChannel;
}) => {
  const adsIsDisabled = channel && adsDisabled(channel);
  const signageIsDisabled = channel && signageDisabled(channel);
  const onScreenSelect = (screenId: string) => {
    if (selectedScreens.includes(screenId)) {
      onSelect(selectedScreens.filter((id) => id !== screenId));
    } else {
      onSelect([...selectedScreens, screenId]);
    }
  };
  const onSelectAll = () => {
    if (selectedScreens.length === screens.length) {
      onSelect([]);
    } else {
      onSelect(screens.map((screen) => screen._id));
    }
  };
  const theme = useTheme();
  return (
    <Box sx={{ display: "flex", gap: "1rem", flexDirection: "column" }}>
      <Box>
        <Typography variant="body1">
          {t(
            "customerportal.pages.content_channels.select_screens_description"
          )}
        </Typography>
      </Box>
      <Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            p: "0.5rem 0.5rem 0.5rem 1rem",
            background: theme.palette.grey[100],
          }}
        >
          <Typography variant="body2">
            {t("customerportal.pages.content_channels.selectable_screens")}
          </Typography>
          <FormControlLabel
            value="start"
            control={
              <Checkbox
                checked={selectedScreens.length === screens.length}
                onChange={onSelectAll}
              />
            }
            label="Select all"
            labelPlacement="start"
          />
        </Box>
        <Grid
          container
          columns={{ xs: 1, md: 2, lg: 4 }}
          spacing={"1rem"}
          paddingTop="1rem"
        >
          {screens.map((screen) => (
            <Grid item xs={1} key={screen._id}>
              <FormControlLabel
                value={screen._id}
                control={
                  <Checkbox
                    checked={selectedScreens.includes(screen._id)}
                    onChange={() => onScreenSelect(screen._id)}
                  />
                }
                label={screen.name}
                labelPlacement="end"
              />
            </Grid>
          ))}
        </Grid>
        <Box sx={{ display: "flex", flexDirection: "column", gap: "0.5rem" }}>
          {signageIsDisabled && (
            <Box>
              <Alert severity="info">
                {t("customerportal.notifications.signage_disabled")}
              </Alert>
            </Box>
          )}
          {adsIsDisabled && (
            <Box>
              <Alert severity="info">
                {t("customerportal.notifications.ads_disabled")}
              </Alert>
            </Box>
          )}
        </Box>
      </Box>
    </Box>
  );
};

export default SetDefaultChannel;
