import {
  Close,
  ExpandMore,
  InfoOutlined,
  TvOutlined,
  VolumeOff,
  VolumeUp,
} from "@mui/icons-material";
import {
  Box,
  Button,
  Fade,
  Grid,
  Stack,
  Theme,
  Tooltip,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { TimeIcon } from "@mui/x-date-pickers";
import { isAfter, isBefore } from "date-fns";
import { getTimezoneOffset } from "date-fns-tz";
import { t } from "i18next";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Hour, Minute } from "../../../../../../types/HelperTypes";
import {
  IRoom,
  LoadingStatus,
  Resource,
  ScheduleContentRefType,
  Scope,
} from "../../../../../../types/NendaTypes";
import { clipString } from "../../../../../utils/string";
import ConfirmationDialog from "../../../ConfirmationDialog";
import { CustomerPortalState, store } from "../../../store";
import { selectContentChannels } from "../../../store/reducers/contentChannelReducer";
import {
  getPlaylists,
  selectStatus,
} from "../../../store/reducers/dailyContentPlaylistReducer";
import { selectPremiseById } from "../../../store/reducers/organizationUnitReducer";
import {
  getCompanyPromotions,
  getPremisePromotions,
  selectDailyPromotions,
  selectPromotions,
} from "../../../store/reducers/promotionReducer";
import {
  ContentTimelineInterval,
  batchUnsetChannelOverride,
  unsetChannelOverride,
} from "../../../store/reducers/screenReducer";
import {
  selectNavigatedCompanyId,
  selectNavigatedPremiseId,
} from "../../../store/reducers/workspaceReducer";
import DailyTimeScale from "../../DailyPlaylist/DailyTimeScale";
import PlaylistTimeline from "../../DailyPlaylist/PlaylistTimeline";
import EditScreenDialog from "./EditScreenDialog";
import PromotionOverviewListItem from "./components/PromotionOverviewListItem";
import PromotionsHeatMap, {
  getDimensions,
} from "./components/PromotionsHeatMap";
import ScreenListItemInfo from "./components/ScreenListItemInfo";
import PermissionsGate from "../../PermissionGate";
import { signageDisabled } from "../../Content/components/DisabledFeatures";
import {
  getCompanySignagePlaylists,
  getPremiseSignagePlaylists,
  selectSignagePlaylists,
} from "@client/components/CustomerPortal/store/reducers/signagePlaylistReducer";

interface ScreenOverviewListProps {
  screens: IRoom[];
  timelines: Record<string, ContentTimelineInterval[]>;
}

const ScreenOverviewList: React.FC<ScreenOverviewListProps> = ({
  screens,
  timelines,
}) => {
  const [expandedRows, setExpandedRows] = useState<string[]>([]);
  const [editScreen, setEditScreen] = useState<IRoom | null>(null);
  const premise = useSelector(selectNavigatedPremiseId);
  const company = useSelector(selectNavigatedCompanyId);
  const companyObject = useSelector((state: CustomerPortalState) =>
    state.company.companies.find((c) => c._id === company)
  );
  const signagePlaylists = useSelector(selectSignagePlaylists);
  const playlistLoadingStatus = useSelector(selectStatus);
  const promotions = useSelector(selectPromotions);
  const dailyPromotions = useSelector(selectDailyPromotions);
  const companyPromotionsIsLoading = useSelector(
    (state: CustomerPortalState) =>
      state.promotion.companyPromotionsStatus === LoadingStatus.LOADING
  );

  const premisePromotionsIsLoading = useSelector(
    (state: CustomerPortalState) =>
      state.promotion.premisePromotionsStatus === LoadingStatus.LOADING
  );

  const premiseObject = useSelector((state: CustomerPortalState) =>
    selectPremiseById(state, premise || "")
  );
  const contentChannels = useSelector(selectContentChannels);
  const noSignageChannels = contentChannels
    .filter((cc) => signageDisabled(cc))
    .map((cc) => cc._id);

  useEffect(() => {
    if (!premise || playlistLoadingStatus === LoadingStatus.LOADING) return;

    store.dispatch(getPlaylists(premise));
  }, [premise]);

  useEffect(() => {
    if (!premise || premisePromotionsIsLoading) return;
    store.dispatch(getPremisePromotions(premise));
  }, [premise]);

  useEffect(() => {
    if (!company || companyPromotionsIsLoading) return;
    store.dispatch(getCompanyPromotions(company));
  }, [company]);

  useEffect(() => {
    if (company) {
      store.dispatch(getCompanySignagePlaylists(company));
    }
    if (premise) {
      store.dispatch(getPremiseSignagePlaylists(premise));
    }
  }, [company, premise]);

  const handleExpandRow = (id: string) => {
    if (expandedRows.includes(id)) {
      setExpandedRows(expandedRows.filter((row) => row !== id));
    } else {
      setExpandedRows([...expandedRows, id]);
    }
  };

  const handleEditScreenClose = () => {
    setEditScreen(null);
  };

  const handleUnsetChannelOverride = (screenId: string) => {
    store.dispatch(unsetChannelOverride(screenId));
  };

  const handleUnsetAllChannelOverrides = () => {
    store.dispatch(batchUnsetChannelOverride(screens.map((s) => s._id)));
  };

  const [unsetChannelOverrideDialogIsOpen, setUnsetChannelOverrideDialogOpen] =
    useState({ single: "", all: false });

  const batchUnsetChannelDialogClose = (isConfirmed: boolean) => {
    if (isConfirmed) {
      handleUnsetAllChannelOverrides();
    }
    setUnsetChannelOverrideDialogOpen({ single: "", all: false });
  };

  const singleUnsetChannelDialogClose = (isConfirmed: boolean) => {
    if (isConfirmed) {
      handleUnsetChannelOverride(unsetChannelOverrideDialogIsOpen.single);
    }
    setUnsetChannelOverrideDialogOpen({ single: "", all: false });
  };

  const openUnsetAllDialog = () => {
    setUnsetChannelOverrideDialogOpen({ single: "", all: true });
  };
  const isMobile = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down("md")
  );
  const screensWithOverrides = screens.map((screen) => {
    let screenOverrideIsActive = false;
    const overrideFromString = screen.signage.config?.contentOverride?.from;
    const overrideToString = screen.signage.config?.contentOverride?.to;
    let contentOverrideName: string | undefined = "";

    const content =
      screen.signage.config?.contentOverride?.contentRefType ===
      ScheduleContentRefType.CONTENT_CHANNEL
        ? contentChannels
        : signagePlaylists;

    if (overrideFromString && overrideToString) {
      const overrideFrom = new Date(overrideFromString);
      const overrideTo = new Date(overrideToString);
      const timeZone = premiseObject?.address.timezone || "Europe/Berlin";
      const offset = getTimezoneOffset(timeZone, new Date(overrideFrom));

      const clientFrom = new Date(new Date(overrideFrom.getTime() + offset));
      const clientTo = new Date(new Date(overrideTo).getTime() + offset);

      const clientNow = new Date(new Date().getTime() + offset);

      const overrideIsActive =
        isBefore(clientNow, clientTo) && isAfter(clientNow, clientFrom);

      if (overrideIsActive) {
        screenOverrideIsActive = true;
        contentOverrideName = content.find(
          (c) => c._id === screen.signage.config?.contentOverride?.content
        )?.name;
      }
    }
    return {
      ...screen,
      isOverridden: screenOverrideIsActive,
      overrideContent: contentOverrideName,
    };
  });
  const theme = useTheme();
  const hasOverrides = screensWithOverrides.some((s) => s.isOverridden);
  return (
    <Box sx={{ mb: "5rem" }}>
      <ConfirmationDialog
        title={t("customerportal.pages.dashboard.confirmation_title")}
        content={t(
          "customerportal.pages.dashboard.confirmation_description_unset_all_overrides"
        )}
        isOpen={unsetChannelOverrideDialogIsOpen.all}
        onClose={batchUnsetChannelDialogClose}
      />
      <ConfirmationDialog
        title={t("customerportal.pages.dashboard.confirmation_title")}
        content={t(
          "customerportal.pages.dashboard.confirmation_description_unset_overrides"
        )}
        isOpen={!!unsetChannelOverrideDialogIsOpen.single}
        onClose={singleUnsetChannelDialogClose}
      />
      {editScreen && (
        <EditScreenDialog
          screen={editScreen}
          onClose={handleEditScreenClose}
          availablePromotions={promotions}
        />
      )}
      <Grid container columns={20} spacing={"0.5rem"} alignItems={"baseline"}>
        <Grid item xs={20} md={4}>
          <Box sx={{ p: "0.5rem" }}>
            {hasOverrides && (
              <Tooltip
                title={t(
                  "customerportal.pages.dashboard.remove_all_overrides_button_help"
                )}
              >
                <Button
                  onClick={openUnsetAllDialog}
                  variant={isMobile ? "outlined" : "text"}
                  size="small"
                  fullWidth={isMobile}
                >
                  {t("customerportal.pages.dashboard.remove_channel_overrides")}
                </Button>
              </Tooltip>
            )}
          </Box>
        </Grid>
        <Grid item xs={1}>
          <Box
            sx={{
              display: isMobile ? "none" : "flex",
              justifyContent: "flex-end",
              alignItems: "flex-end",
              [theme.breakpoints.down("md")]: {
                display: "none",
              },
            }}
          >
            <TimeIcon sx={{ height: "1rem", transform: "translateY(.2rem)" }} />
          </Box>
        </Grid>
        <Grid item xs={14}>
          <Box
            sx={(theme) => ({
              p: "0.5rem",
              [theme.breakpoints.down("md")]: {
                display: "none",
              },
            })}
          >
            <DailyTimeScale />
          </Box>
        </Grid>
      </Grid>

      <Stack direction="column" gap="0.5rem">
        {screensWithOverrides.map((screen, i) => {
          const promotionsOnScreenToday = dailyPromotions.filter(
            (promotion) => {
              return screen.signage.schedule.promotions.includes(promotion._id);
            }
          );

          const timelinesWithBlockedSignage = timelines[screen._id]?.filter(
            (tl) => {
              return noSignageChannels.includes(tl.contentId);
            }
          );

          const blockedRangeDimensions = timelinesWithBlockedSignage?.map(
            (tl) => {
              const startTime = {
                hour: tl.start.getUTCHours() as Hour,
                minute: tl.start.getUTCMinutes() as Minute,
              };
              const endTime = {
                hour: tl.end.getUTCHours() as Hour,
                minute: tl.end.getUTCMinutes() as Minute,
              };

              return getDimensions(startTime, endTime);
            }
          );

          const handleOpenUnsetDialog = () => {
            setUnsetChannelOverrideDialogOpen({
              single: screen._id,
              all: false,
            });
          };

          return (
            <Box
              key={`${screen._id}-${screen.name}-${i}`}
              sx={(theme) => ({
                position: "relative",
                display: "flex",
                overflow: "hidden",
                flexDirection: "column",
                background: theme.palette.background.paper,
                borderRadius: "0.2rem",
                "& .gear-icon": {
                  opacity: 0,
                  transition: "all 0.1s ease-in-out",
                  "&:hover": {
                    fill: theme.palette.primary.light,
                  },
                },
                "&:hover": {
                  "& .gear-icon": {
                    opacity: 1,
                  },
                },
              })}
            >
              {screen.overrideContent && screen.isOverridden && (
                <Tooltip
                  title={t(
                    "customerportal.pages.dashboard.remove_single_override_help"
                  )}
                >
                  <Box
                    sx={(theme) => ({
                      pl: "0.5rem",
                      py: "0.2rem",
                      borderRadius: "0.2rem",
                      background: theme.palette.transparent.hardPurple,
                      width: "fit-content",
                      display: "flex",
                      gap: "0.3rem",
                      alignItems: "center",
                    })}
                  >
                    <Typography
                      sx={(theme) => ({
                        color: theme.palette.text.secondary,
                        fontSize: "10px",
                      })}
                    >
                      {t("customerportal.pages.dashboard.temporarily_playing") +
                        ": " +
                        screen.overrideContent}
                    </Typography>
                    <Close
                      sx={{ height: "0.8rem", cursor: "pointer" }}
                      onClick={handleOpenUnsetDialog}
                    />
                  </Box>
                </Tooltip>
              )}
              <Grid
                container
                columns={20}
                spacing={"0.5rem"}
                alignItems={"center"}
              >
                <Grid item md={4} xs={20}>
                  <Box
                    sx={{
                      px: "1rem",
                      display: "flex",
                      gap: "1rem",
                      alignItems: "center",
                      cursor: "pointer",
                      py: "0.3rem",
                    }}
                    onClick={() => setEditScreen(screen)}
                  >
                    {screen.signage?.config?.numberOfSceens ? (
                      <TvOutlined
                        sx={(theme) => ({ fill: theme.palette.primary.light })}
                      />
                    ) : (
                      <PermissionsGate
                        DeniedComponent={() => (
                          <TvOutlined
                            sx={(theme) => ({
                              fill: theme.palette.primary.light,
                            })}
                          />
                        )}
                        restriction={{
                          resource: Resource.Premise,
                          scopes: [Scope.CanAdministrate],
                        }}
                      >
                        <Tooltip
                          title={`${t(
                            "customerportal.pages.dashboard.number_of_screens_missing"
                          )}`}
                        >
                          <InfoOutlined color="warning" />
                        </Tooltip>
                      </PermissionsGate>
                    )}
                    <Box>
                      <Tooltip
                        title={
                          <Box>
                            <Typography variant="body2">
                              {t("customerportal.pages.dashboard.name")}
                            </Typography>
                            <Typography
                              variant="body1"
                              sx={{ fontSize: "0.8rem" }}
                            >
                              {screen.name}
                            </Typography>
                            {screen.description && (
                              <>
                                <Typography variant="body2">
                                  {t("customerportal.pages.dashboard.name")}
                                </Typography>
                                <Typography
                                  variant="body1"
                                  sx={{ fontSize: "0.8rem" }}
                                >
                                  {screen.description}
                                </Typography>
                              </>
                            )}
                            <PermissionsGate
                              restriction={{
                                resource: Resource.Premise,
                                scopes: [Scope.CanAdministrate],
                              }}
                            >
                              <>
                                {screen.adnuntiusAuId && (
                                  <>
                                    <Typography variant="body2">
                                      {t(
                                        "customerportal.pages.dashboard.external_ad_service_id"
                                      )}
                                    </Typography>
                                    <Typography
                                      variant="body1"
                                      sx={{ fontSize: "0.8rem" }}
                                    >
                                      {screen.adnuntiusAuId}
                                    </Typography>
                                  </>
                                )}
                              </>
                            </PermissionsGate>
                          </Box>
                        }
                      >
                        <Box
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            gap: "0.2rem",
                            pb: "0.5rem",
                          }}
                        >
                          <Typography
                            variant="body2"
                            sx={(theme) => ({
                              color: theme.palette.primary.light,
                            })}
                          >
                            {clipString(screen.name, 20)}
                          </Typography>
                          <Box
                            sx={{
                              display: "inline-flex",
                              alignItems: "baseline",
                              transform: "translateY(-1px)",
                            }}
                          >
                            {screen.signage.config?.isSoundEnabled ? (
                              <VolumeUp
                                sx={(theme) => ({
                                  height: "0.8rem",
                                  padding: 0,
                                  transform: "translateY(3px)",
                                  fill: theme.palette.grey[800],
                                })}
                              />
                            ) : (
                              <VolumeOff
                                sx={(theme) => ({
                                  height: "0.8rem",
                                  padding: 0,
                                  transform: "translateY(3px)",
                                  fill: theme.palette.grey[800],
                                })}
                              />
                            )}
                            <Typography
                              variant="caption"
                              fontSize={"0.6rem"}
                              sx={(theme) => ({
                                color: theme.palette.grey[600],
                                textTransform: "uppercase",
                                fontWeight: "bold",
                              })}
                            >
                              {screen.signage.config?.isSoundEnabled
                                ? t("common.sound_on")
                                : t("common.sound_off")}
                            </Typography>
                          </Box>
                        </Box>
                      </Tooltip>
                      <Box sx={{ display: "flex", alignItems: "baseline" }}>
                        <ScreenListItemInfo
                          screen={screen}
                          company={companyObject}
                          premise={premiseObject}
                        />
                      </Box>
                    </Box>
                  </Box>
                </Grid>
                <Grid item sm={0} md={1}>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "space-between",
                      gap: "1.5rem",
                      [theme.breakpoints.down("md")]: {
                        display: "none",
                      },
                    }}
                  >
                    <Typography variant="body1" fontSize={"0.6rem"}>
                      {t("customerportal.pages.dashboard.content")}
                    </Typography>
                    <Typography variant="body1" fontSize={"0.6rem"}>
                      {t("customerportal.pages.dashboard.promotions")}
                    </Typography>
                  </Box>
                </Grid>
                <Grid item md={14} xs={1}>
                  <Box
                    sx={(theme) => ({
                      p: "0.5rem",
                      display: "flex",
                      flexDirection: "column",
                      gap: "0.2rem",
                      [theme.breakpoints.down("md")]: {
                        display: "none",
                      },
                    })}
                  >
                    <PlaylistTimeline playlist={timelines[screen._id] || []} />
                    <PromotionsHeatMap promotions={promotionsOnScreenToday} />
                  </Box>
                </Grid>
                <Grid item xs={13} md={1}>
                  <Box
                    sx={(theme) => ({
                      p: "0.5rem",
                      cursor: "pointer",
                      [theme.breakpoints.down("md")]: { display: "none" },
                    })}
                    onClick={() => handleExpandRow(screen._id)}
                  >
                    <ExpandMore />
                  </Box>
                </Grid>
              </Grid>
              <Fade in={expandedRows.includes(screen._id)} unmountOnExit appear>
                <Box
                  sx={{
                    background: "rgb(255, 255, 255)",
                    boxShadow: `inset 0px 65px 112px -79px rgba(128, 125, 211, 0.357)`,
                    display: expandedRows.includes(screen._id)
                      ? "block"
                      : "none",
                  }}
                >
                  <Box>
                    <Box sx={{ px: "1rem", py: "0.5rem" }}>
                      <Typography variant="subtitle1">
                        {t(
                          "customerportal.pages.dashboard.scheduled_promotions"
                        )}
                      </Typography>
                    </Box>
                    {promotionsOnScreenToday?.map((p) => {
                      return (
                        <PromotionOverviewListItem
                          promotion={p}
                          key={p._id + screen._id}
                          blockedRangeDimensions={blockedRangeDimensions}
                        />
                      );
                    })}
                  </Box>
                </Box>
              </Fade>
            </Box>
          );
        })}
      </Stack>
    </Box>
  );
};

export default ScreenOverviewList;
