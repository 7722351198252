import { Edit } from "@mui/icons-material";
import { TabContext, TabList } from "@mui/lab";
import { Button, Divider, Tab } from "@mui/material";
import { Box } from "@mui/system";
import { t } from "i18next";
import { useCallback, useState } from "react";
import { useSelector } from "react-redux";
import {
  IAddress,
  IBusinessHours,
  IContactPerson,
  IContract,
  IOrganizationUnit,
  ISignageConfig,
  NendaProduct,
  Resource,
  Scope,
  SignageSlotAmount,
} from "../../../../../../types/NendaTypes";
import { store } from "../../../store";
import {
  getPermissions,
  selectUserRole,
} from "../../../store/reducers/authReducer";
import { UpdateOrganizationUnit } from "../../../store/reducers/organizationUnitReducer";
import DefaultDialog from "../../../ui-components/dialog/dialog";
import EditScreens from "../../Premise/Features/EditScreens";
import PremiseInfo from "../../Premise/Info/PremiseInfo";
import { checkPermission } from "../../helpers/permissions";
import PremiseFeatures from "./PremiseFeatures";
import { CountryCode, getCountry, TimezoneName } from "countries-and-timezones";
import { DayOfWeek } from "@nendaTypes/HelperTypes";

const EditPremiseDialog = ({
  premise,
}: {
  premise: IOrganizationUnit & {
    signageConfig: ISignageConfig;
  };
}) => {
  const [showEditPremise, setShowEditPremise] = useState(false);
  const [tabValue, setTabValue] = useState("info");

  const initAddressData = {
    ...premise.address,
    city: premise.address?.city || "",
    streetAddress: premise.address?.streetAddress || "",
    postalCode: premise.address?.postalCode || "",
    region: premise.address?.region || "",
  };
  const initData: IOrganizationUnit & {
    signageConfig: ISignageConfig;
  } = {
    ...premise,
    address: initAddressData,
    signageConfig: {
      ...premise.signageConfig,
      numberOfHourlySignageSlots:
        premise.signageConfig?.numberOfHourlySignageSlots,
      isSignageAsContentEnabled:
        premise.signageConfig?.isSignageAsContentEnabled,
      arePromotionsEnabled: premise.signageConfig?.arePromotionsEnabled,
    },
  };

  const [editPremiseData, setEditPremiseData] = useState<
    IOrganizationUnit & {
      signageConfig: ISignageConfig;
    }
  >(initData);

  if (premise._id !== editPremiseData._id) {
    setEditPremiseData(initData);
  }

  const role = useSelector(selectUserRole);
  const permissions = useSelector(getPermissions);

  const handleProductChange = (productName: NendaProduct) => {
    const nendaProducts = editPremiseData.nendaProducts || [];
    const productIndex = nendaProducts.indexOf(productName);
    if (productIndex === -1) {
      setEditPremiseData((prevFormState) => ({
        ...prevFormState,
        nendaProducts: [...nendaProducts, productName],
      }));
    } else {
      setEditPremiseData((prevFormState) => ({
        ...prevFormState,
        nendaProducts: prevFormState.nendaProducts.filter(
          (_, i) => i !== productIndex
        ),
      }));
    }
  };

  const handleStreamingContentMangerEnabledChange = (value: boolean) => {
    setEditPremiseData((prevFormState) => ({
      ...prevFormState,
      streamingContentManagerEnabled: value,
    }));
  };

  const handleSignageSlotChange = (value: SignageSlotAmount) => {
    if (!editPremiseData.signageConfig) {
      return;
    }
    setEditPremiseData({
      ...editPremiseData,
      signageConfig: {
        ...editPremiseData.signageConfig,
        numberOfHourlySignageSlots: value,
      },
    });
  };

  const handleSignageAsContentEnabledChange = (value: boolean) => {
    if (!editPremiseData.signageConfig) {
      return;
    }
    setEditPremiseData({
      ...editPremiseData,
      signageConfig: {
        ...editPremiseData.signageConfig,
        isSignageAsContentEnabled: value,
      },
    });
  };

  const handlePromotionsEnabledChange = (value: boolean) => {
    if (!editPremiseData.signageConfig) {
      return;
    }
    setEditPremiseData({
      ...editPremiseData,
      signageConfig: {
        ...editPremiseData.signageConfig,
        arePromotionsEnabled: value,
      },
    });
  };

  const handleCompanyChange = (companyId: string) => {
    setEditPremiseData((prevFormState) => ({
      ...prevFormState,
      company: companyId,
    }));
  };

  const handleContentChannelsChange = (contentChannels: string[]) => {
    setEditPremiseData((prevFormState) => ({
      ...prevFormState,
      contentChannels,
    }));
  };

  const handleAddressChange = (address: IAddress) => {
    setEditPremiseData((prevState) => ({ ...prevState, address }));
  };

  const handleCountryChange = (country: CountryCode) => {
    if (editPremiseData.address)
      setEditPremiseData((prevState) => ({
        ...prevState,
        address: {
          ...prevState.address,
          country,
          timezone: getCountry(country).timezones[0] as TimezoneName,
        },
      }));
  };

  const handleTimezoneChange = (timezone: TimezoneName) => {
    if (editPremiseData.address)
      setEditPremiseData((prevState) => ({
        ...prevState,
        address: { ...prevState.address, timezone },
      }));
  };
  const handleContactPersonChange = (contactPerson: IContactPerson) => {
    setEditPremiseData((prevState) => ({ ...prevState, contactPerson }));
  };
  const handleContractChange = (contract: IContract) => {
    setEditPremiseData((prevState) => ({ ...prevState, contract }));
  };

  const handleNumberOfEntriesChange = (entries: number) => {
    setEditPremiseData((prevState) => ({
      ...prevState,
      numberOfEntries: entries,
    }));
  };
  const handleDwellTimeChange = (dwellTimeInMinutes: number) => {
    setEditPremiseData((prevState) => ({ ...prevState, dwellTimeInMinutes }));
  };
  const handleCategoryChange = (category: string) => {
    setEditPremiseData((prevState) => ({ ...prevState, category }));
  };

  const handleBusinessHoursChange = (
    businessHours: Partial<Record<DayOfWeek, IBusinessHours>>
  ) => {
    setEditPremiseData((prevState) => ({
      ...prevState,
      businessHours: businessHours,
    }));
  };

  const editPremiseDialogContent = useCallback(() => {
    if (!premise) return [];
    const items = [
      {
        label: "Info",
        value: "info",
        restriction: {
          resource: Resource.Premise,
          scopes: [Scope.CanEdit],
        },
        component: (
          <PremiseInfo
            handleAddressChange={handleAddressChange}
            handleTimezoneChange={handleTimezoneChange}
            handleCountryChange={handleCountryChange}
            handleContactPersonChange={handleContactPersonChange}
            handleContractChange={handleContractChange}
            handleCategoryChange={handleCategoryChange}
            handleNumberOfEntriesChange={handleNumberOfEntriesChange}
            handleDwellTimeChange={handleDwellTimeChange}
            handleBusinessHoursChange={handleBusinessHoursChange}
            premiseInfo={editPremiseData}
          />
        ),
      },
      {
        label: "Features",
        value: "features",
        restriction: {
          resource: Resource.Premise,
          scopes: [Scope.CanAdministrate],
        },
        component: (
          <PremiseFeatures
            premise={premise}
            handleProductChange={handleProductChange}
            handleStreamingContentMangerEnabledChange={
              handleStreamingContentMangerEnabledChange
            }
            handleSignageAsContentEnabledChange={
              handleSignageAsContentEnabledChange
            }
            handlePromotionsEnabledChange={handlePromotionsEnabledChange}
            handleCompanyChange={handleCompanyChange}
            handleSignageSlotChange={handleSignageSlotChange}
            handleContentChannelsChange={handleContentChannelsChange}
            premiseFeatures={editPremiseData}
          />
        ),
      },
      {
        label: "Screens",
        value: "screens",
        restriction: {
          resource: Resource.Premise,
          scopes: [Scope.CanAdministrate],
        },
        component: <EditScreens premise={premise} />,
      },
    ];
    return items;
  }, [editPremiseData]);
  const tabItems = editPremiseDialogContent();

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  const saveChanges = ({ isConfirmed }) => {
    if (!isConfirmed) return setShowEditPremise(false);

    const updatedPremise: Partial<IOrganizationUnit> = {
      address: editPremiseData?.address,
      contactPerson: editPremiseData?.contactPerson,
      contract: editPremiseData?.contract && {
        ...editPremiseData?.contract,
        startDate: JSON.parse(
          JSON.stringify(editPremiseData?.contract?.startDate)
        ),
        endDate: JSON.parse(JSON.stringify(editPremiseData?.contract?.endDate)),
      },
      numberOfEntries: editPremiseData?.numberOfEntries,
      dwellTimeInMinutes: editPremiseData?.dwellTimeInMinutes,
      nendaProducts: editPremiseData?.nendaProducts,
      category: editPremiseData?.category,
      businessHours: editPremiseData?.businessHours,
      company: editPremiseData?.company,
      contentChannels: editPremiseData?.contentChannels,
      signageConfig: editPremiseData?.signageConfig,
      streamingContentManagerEnabled: editPremiseData.nendaProducts?.includes(
        NendaProduct.STREAMING_APP
      )
        ? editPremiseData?.streamingContentManagerEnabled
        : false, //Turns off this feature if the product is disabled
    };
    store.dispatch(UpdateOrganizationUnit(premise?._id, updatedPremise));

    setShowEditPremise(false);
  };

  return (
    <Box>
      <Button
        variant="outlined"
        size="small"
        startIcon={<Edit />}
        onClick={() => setShowEditPremise(true)}
        sx={{ ml: "auto" }}
      >
        {t("customerportal.pages.dashboard.edit_premise")}
      </Button>
      <DefaultDialog
        onClose={saveChanges}
        open={showEditPremise}
        title={t("customerportal.pages.dashboard.edit_premise") as string}
      >
        <>
          <Box>
            <TabContext value={tabValue}>
              <TabList onChange={handleTabChange} sx={{ mb: "1rem" }}>
                {role &&
                  tabItems
                    .filter((i) =>
                      checkPermission(role, i.restriction, permissions)
                    )
                    .map((item) => (
                      <Tab
                        key={item.value}
                        label={item.label}
                        value={item.value}
                      />
                    ))}
              </TabList>
              <Divider />
            </TabContext>
          </Box>

          <Box>
            {
              editPremiseDialogContent().find((item) => item.value === tabValue)
                ?.component
            }
          </Box>

          <Divider />
        </>
      </DefaultDialog>
    </Box>
  );
};

export default EditPremiseDialog;
