import {
  Box,
  Tooltip,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { AssetType, IAsset } from "@nendaTypes/NendaTypes";
import ClickableImagePreview from "../../ui-components/media/ClickableImagePreview";
import { BusinessOutlined } from "@mui/icons-material";
import useMediaFileInformation from "@client/hooks/useVideoFileInformation";
import { clipString } from "@client/utils/string";
import { t } from "i18next";
import { memo } from "react";

const SelectableAssetsGrid = (props: SelectableAssetsGridProps) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  return (
    <Box
      sx={{
        display: "flex",
        flexWrap: isMobile ? "nowrap" : "wrap",
        flexDirection: isMobile ? "column" : "row",
        py: "0.5rem",
      }}
    >
      {props.assets.map((asset) => (
        <SelectableAssetCard
          key={asset._id}
          asset={asset}
          onClick={props.onSelectAsset}
          selectedAssetId={props.selectedAssetId}
        />
      ))}
    </Box>
  );
};

const SelectableAssetCard = ({
  asset,
  onClick,
  selectedAssetId,
}: {
  asset: IAsset;
  onClick: (assetId: string, duration?: number | null) => void;
  selectedAssetId?: string | null;
}) => {
  const isVideo = asset.type === AssetType.VIDEO;
  const fileInfo = useMediaFileInformation(asset.locator);
  let duration: number | null = null;
  if (isVideo && fileInfo.duration) {
    duration = Number(fileInfo.duration.toFixed());
  }
  const handleSelectAsset = () => {
    if (!isVideo) {
      onClick(asset._id);
    } else {
      onClick(asset._id, duration);
    }
  };
  const isPremiseAsset = !!asset.premise;
  const isSelected = selectedAssetId === asset._id;

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  return (
    <Box
      onClick={handleSelectAsset}
      sx={{
        "&:hover": { opacity: 0.7, background: theme.palette.transparent.blue },
        opacity: 1,
        cursor: "pointer",
        mb: "0.5rem",
        p: "0.5rem",
        borderRadius: "0.2rem",
        background: isSelected ? theme.palette.transparent.blue : "transparent",
        outline: isSelected
          ? `3px solid ${theme.palette.primary.light}`
          : "none",
      }}
    >
      <Box
        sx={{
          height: isMobile ? "20rem" : "12rem",
          width: isMobile ? "100%" : "11rem",
          overflow: "hidden",
        }}
      >
        <Box
          sx={{
            height: "70%",
            background: "black",
            display: "flex",
            alignItems: "center",
          }}
        >
          <ClickableImagePreview
            url={asset.locator || ""}
            thumbnail={asset.thumbnailLocator}
            type={asset.type}
            width="100%"
          />
        </Box>
        <Box sx={{ py: "0.3rem" }}>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <Box sx={{ display: "flex", alignItems: "center", gap: "0.3rem" }}>
              <Typography
                sx={{
                  textTransform: "uppercase",
                  color: "grey",
                  fontSize: "0.7rem",
                }}
              >
                {asset.type}
              </Typography>
              <Typography
                sx={{
                  color: "grey",
                  fontSize: "0.7rem",
                }}
              >
                {isVideo && duration ? ` (${duration}s)` : ""}
              </Typography>
            </Box>
            {!isPremiseAsset && (
              <Tooltip
                title={t("customerportal.pages.digital_signage.company_asset")}
              >
                <BusinessOutlined sx={{ height: "0.9rem" }} />
              </Tooltip>
            )}
          </Box>
          <Tooltip title={asset.name}>
            <Typography variant="body1">
              {clipString(asset.name, 15)}
            </Typography>
          </Tooltip>
        </Box>
      </Box>
    </Box>
  );
};

type SelectableAssetsGridProps = {
  assets: IAsset[];
  selectedAssetId: string | undefined;
  onSelectAsset: (assetId: string) => void;
};

export default memo(SelectableAssetsGrid);
