import { DataGrid, GridColDef, GridToolbar } from "@mui/x-data-grid";
import {
  Box,
  IconButton,
  Link,
  Tooltip,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import {
  selectCurrentNavigationLevel,
  selectNavigationScope,
} from "../../store/reducers/workspaceReducer";
import { ISignagePlaylist, NavigationLevel } from "@nendaTypes/NendaTypes";
import { clipString } from "@client/utils/string";
import { BusinessOutlined, DeleteOutline } from "@mui/icons-material";
import { store } from "../../store";
import {
  deleteCompanySignagePlaylist,
  deletePremiseSignagePlaylist,
} from "../../store/reducers/signagePlaylistReducer";
import ConfirmationDialog from "../../ConfirmationDialog";
import { useState } from "react";
import { t } from "i18next";

type DigitalSignageTableProps = {
  playlists: ISignagePlaylist[];
};

const DigitalSignageTable = ({ playlists }: DigitalSignageTableProps) => {
  const navigate = useNavigate();
  const navigationLevel = useSelector(selectCurrentNavigationLevel);
  const scope = useSelector(selectNavigationScope);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  const rows = playlists.map((playlist) => ({
    id: playlist._id,
    name: playlist.name,
    company: playlist.company,
    premise: playlist.premise,
    items: playlist.items.length,
    defaultItemDuration: playlist.defaultItemDuration,
    isSoundEnabled: playlist.isSoundEnabled,
    updatedAt: new Date(playlist.updatedAt || new Date()).toLocaleString(),
    duration: playlist.items
      .map((item) => item.durationInSeconds)
      .reduce((a, b) => a! + b!, 0),
  }));

  const [deleteSignagePlaylist, setDeleteSignagePlaylist] = useState<{
    isOpen: boolean;
    playlistScope: NavigationLevel | null;
    playlistId: string;
    playlistScopeId: string;
  }>({
    isOpen: false,
    playlistScope: null,
    playlistId: "",
    playlistScopeId: "",
  });
  const handleDelete = (
    playlistId: string,
    playlistScope: NavigationLevel,
    playlistScopeId: string
  ) => {
    setDeleteSignagePlaylist({
      isOpen: true,
      playlistScope,
      playlistScopeId,
      playlistId,
    });
  };
  const onDeleteConfirmed = (isConfirmed: boolean) => {
    if (isConfirmed) {
      if (deleteSignagePlaylist.playlistScope === NavigationLevel.PREMISE) {
        store.dispatch(
          deletePremiseSignagePlaylist({
            premiseId: deleteSignagePlaylist.playlistScopeId,
            id: deleteSignagePlaylist.playlistId,
          })
        );
      } else if (
        deleteSignagePlaylist.playlistScope === NavigationLevel.COMPANY
      ) {
        store.dispatch(
          deleteCompanySignagePlaylist({
            companyId: deleteSignagePlaylist.playlistScopeId,
            id: deleteSignagePlaylist.playlistId,
          })
        );
      }
    }
    setDeleteSignagePlaylist({
      isOpen: false,
      playlistScope: null,
      playlistId: "",
      playlistScopeId: "",
    });
  };
  const columns: GridColDef[] = [
    { field: "id", headerName: "ID", flex: 1 },
    {
      field: "name",
      headerName: t(
        "customerportal.pages.digital_signage.list.header.name"
      ) as string,
      flex: 1,
      renderCell: (params) => {
        const navigationLink = `/${navigationLevel}/${
          navigationLevel === "premise" ? scope.premise : scope.company
        }/digital-signage-playlists/${params.row.id}`;
        return (
          <Link
            onClick={() => navigate(navigationLink)}
            sx={{ cursor: "pointer" }}
          >
            {clipString(params.row.name, 50)}
          </Link>
        );
      },
    },
    { field: "updatedAt", headerName: "Updated At", flex: 1 },
    { field: "company", headerName: "Company", flex: 1 },
    { field: "premise", headerName: "Premise", flex: 1 },
    {
      field: "isCompanyPlaylist",
      headerName: "",
      width: 50,
      renderCell: (params) => {
        return !params.row.premise ? (
          <Tooltip
            title={t("customerportal.pages.digital_signage.company_playlist")}
          >
            <BusinessOutlined />
          </Tooltip>
        ) : null;
      },
    },
    {
      field: "duration",
      headerName: t(
        "customerportal.pages.digital_signage.list.header.total_duration.title"
      ) as string,
      description: t(
        "customerportal.pages.digital_signage.list.header.total_duration.description"
      ) as string,
      flex: 1,
      renderCell: (params) => (
        <Typography variant="body1">{`${params.row.duration}s`}</Typography>
      ),
    },
    {
      field: "items",
      headerName: t(
        "customerportal.pages.digital_signage.list.header.num_of_assets.title"
      ) as string,
      description: t(
        "customerportal.pages.digital_signage.list.header.num_of_assets.description"
      ) as string,
      flex: 1,
      renderCell: (params) => (
        <Typography variant="body1">{`${params.row.items}`}</Typography>
      ),
    },
    {
      field: "isSoundEnabled",
      width: isMobile ? 50 : 100,
      description: t(
        "customerportal.pages.digital_signage.list.header.sound.description"
      ) as string,
      headerName: t(
        "customerportal.pages.digital_signage.list.header.sound.title"
      ) as string,
      renderCell: (params) => {
        return (
          <Typography variant="body1">
            {params.row.isSoundEnabled
              ? (t(
                  "customerportal.pages.digital_signage.list.header.sound.on"
                ) as string)
              : (t(
                  "customerportal.pages.digital_signage.list.header.sound.off"
                ) as string)}
          </Typography>
        );
      },
    },
    {
      field: "deleteAction",
      headerName: "",
      width: 50,
      align: "right",
      renderCell: (params) => {
        const onDelete = () => {
          handleDelete(
            params.row.id,
            params.row.premise
              ? NavigationLevel.PREMISE
              : NavigationLevel.COMPANY,
            params.row.premise || params.row.company
          );
        };
        return (
          <Tooltip
            title={t(
              "customerportal.pages.digital_signage.list.delete_playlist"
            )}
          >
            <IconButton onClick={onDelete}>
              <DeleteOutline />
            </IconButton>
          </Tooltip>
        );
      },
    },
  ];
  return (
    <Box style={{ display: "flex", flexDirection: "column" }}>
      <ConfirmationDialog
        isOpen={deleteSignagePlaylist.isOpen}
        onClose={onDeleteConfirmed}
        title={t("customerportal.pages.digital_signage.list.delete_playlist")}
        content={t(
          "customerportal.pages.digital_signage.list.delete_playlist_description",
          {
            playlistName: playlists.find(
              (playlist) => playlist._id === deleteSignagePlaylist.playlistId
            )?.name,
          }
        )}
      />
      <DataGrid
        columnBufferPx={100}
        initialState={{
          filter: {
            filterModel: {
              items: [],
              quickFilterExcludeHiddenColumns: false,
            },
          },
          sorting: {
            sortModel: [{ field: "updatedAt", sort: "desc" }],
          },
        }}
        ignoreDiacritics={true}
        disableColumnSelector
        disableRowSelectionOnClick
        disableDensitySelector
        columnVisibilityModel={{
          updatedAt: false,
          company: false,
          premise: false,
          id: false,
          duration: isMobile ? false : true,
          isSoundEnabled: isMobile ? false : true,
          items: isMobile ? false : true,
        }}
        columns={columns}
        rows={rows}
        slots={{ toolbar: GridToolbar }}
        slotProps={{ toolbar: { showQuickFilter: true } }}
      />
    </Box>
  );
};

export default DigitalSignageTable;
